import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

const NotFoundPage = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      navigate('/sv')
    }
  }, [])

  return <div />
}

export default NotFoundPage
